import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Row, Col } from 'antd';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './otp.less'
import { otpVerify, actionResendOTP } from '../../appRedux/actions';

const OTP = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isOTPVerified } = useSelector(({ otp }) => otp);
    const firstInput = useRef(null);
    const secondInput = useRef(null);
    const thirdInput = useRef(null);
    const fourthInput = useRef(null);
    const {mobile_number, name, prefix, mobwoprefix} = props.location.state
    const [seconds, setSeconds] = useState(30);
    const [startTimer, setStartTimer] = useState(false);


    useEffect(() => {
        if (isOTPVerified) {
            window.location = '/login';
        }
    }, [isOTPVerified]);


    const onFinish = (values) => {
        let fdigit = firstInput.current.state.value;
        let sdigit = secondInput.current.state.value;
        let tdigit = thirdInput.current.state.value;
        let fodigit = fourthInput.current.state.value;
        let data = { 
            name: name,
            mobile_number: mobile_number,
            otp: fdigit+''+sdigit+''+tdigit+''+fodigit
        }
        dispatch(otpVerify(data));
    };

    const updateTime = () => {
        if(seconds === 0) {
            setSeconds(30)
            setStartTimer(false)
        }
        else if(seconds <= 30 && seconds > 0){
            setSeconds(seconds - 1)
        }
    }

    useEffect(()=>{
        if(startTimer){
            setTimeout(updateTime, 1000)
        }
    })

    const resendOTP = () => {
        let data = { 
            name: name,
            mobile_number: mobile_number
        }

        dispatch(actionResendOTP(data))
        setStartTimer(true)
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
            }
        }

    

    const handleFirstinput = e => {
        const BACKSPACE_KEY = 8;
        if (e.keyCode !== BACKSPACE_KEY){
            secondInput.current.focus();
        }
    }

    const handleSecondinput = e => {
        const BACKSPACE_KEY = 8;
        if (e.keyCode === BACKSPACE_KEY){
            firstInput.current.focus()
        }
        else{
            thirdInput.current.focus();
        }
    }

    const handleThirdinput = e => {
        const BACKSPACE_KEY = 8;
        if (e.keyCode === BACKSPACE_KEY){
            secondInput.current.focus()
        }
        else{
            fourthInput.current.focus();
        }
    }
    

    const handleFourthinput = e => {
        const BACKSPACE_KEY = 8;
        if (e.keyCode === BACKSPACE_KEY){
            thirdInput.current.focus()
        }
    }


    return (
        <AuthLayout>
            <div className="outer-container-accounts">
                <div className="inner-container-accounts">
                    <span className='span-text'>Enter the OTP Sent to</span>
                    <span className='span-text'>{prefix + ' ' + mobwoprefix.slice(0,2) + '******' + mobwoprefix.slice(-2)}</span>
                    <Row className='otp-wrapper'>
                        <Col xs={4}>
                            <Input
                                name='digit1'
                                ref = {firstInput}
                                type='number' maxLength="1"
                                onInput={maxLengthCheck}
                                onKeyUp={handleFirstinput}
                            />
                        </Col>
                        <Col xs={4}>
                            <Input
                                name='digit2'
                                ref={secondInput}
                                type='number' maxLength="1"
                                onInput={maxLengthCheck}
                                onKeyUp={handleSecondinput}
                            />
                        </Col>
                        <Col xs={4}>
                            <Input
                                name='digit3'
                                ref={thirdInput}
                                type='number' maxLength="1" onInput={maxLengthCheck}
                                onKeyUp={handleThirdinput}
                            />
                        </Col>
                        <Col xs={4}>
                        <Input
                            name='digit4'
                            ref={fourthInput}
                            type='number' maxLength="1" onInput={maxLengthCheck}
                            onKeyUp={handleFourthinput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Button onClick={onFinish} type='primary' htmlType='submit'>
                            Verify
                        </Button>
                    </Row>
                    <Row className='resend-otp-margin'>
                        <p>
                            Didn't received? {
                                            startTimer ?
                                            <span className='ml-5'>
                                                Resend after: {seconds} seconds
                                            </span>
                                            :
                                            <span onClick={resendOTP} className='btn-link ml-5 hover-links-wrapper'>
                                                Resend OTP
                                            </span>
                                            }
                        </p>
                    </Row>
                </div>
            </div>
        </AuthLayout>
    );
};

export default OTP;
